
import { subscription } from '@/api/payment'
import { formatText, piper } from '@/services/global'
// Text formatting cheat sheet:
// [text](link) ^ can be added at end to set the target as 'blank'
// *bold*
// **emphasis**
// --nowrap--
// \n (new line)
// {{var}}

export const en = {
  meta: {
    base: 'Table 4',
    title: '%b - Connecting people, one table at a time',
    titleTemplate: '%s | %b',
    hello: {
      login: {
        title: 'Log in'
      },
      terms: {
        title: 'Terms of service & Privacy policy'
      },
    },
    signup: {
      account: {
        title: 'Create account',
      },
      interests: {
        title: 'Interests',
      },
      gastronomy: {
        title: 'Gastronomy',
      },
      personal: {
        title: 'Personal',
      },
    },
    profile: {
      account: {
        title: 'My account',
      },
      interests: {
        title: 'Interests',
      },
      gastronomy: {
        title: 'Gastronomy',
      },
      personal: {
        title: 'Personal',
      },
    },
    settings: {
      location: {
        title: 'Location',
      },
      tables: {
        title: 'Tables',
      },
      availability: {
        title: 'Availability',
      },
      reservation: {
        title: 'Reservation',
        payment: {
          title: 'Checkout'
        },
        confirmation: {
          title: 'Confirmation'
        },
      }
    },
  },
  hello: {
    logIn: 'Have an account? *Log in*',
    signUp: 'Get started',
    onboarding: [
      {
        title: 'Connecting people, one table at a time.',
        text: ' '
      },
      {
        title: 'Create your Profile',
        text: 'Tell us a bit about yourself: favorite cuisines, interests in life, and a few mildly personal stuff.'
      },
      {
        title: 'Find your table',
        text: 'Choose which tables you would like to join by interest and according to your availability.'
      },
      {
        title: 'Meet your future friends',
        text: 'Show up at our dinners and meet like-minded people selected for you by our algorithm.'
      },
    ],
  },
  login: {
    emailButton: 'Continue with E-mail',
    googleButton: 'Continue with Google',
    facebookButton: 'Continue with Facebook',
    appleButton: 'Continue with Apple',
    invalidEmail: 'Please enter a valid e-mail',
    logIn: 'Log in',
    logOut: 'Log out',
    invalidCredentials: 'Incorrect username and/or password',
    socialUserNotFoundError: 'User not found. Create your account!',
  },
  fillInfo: 'Add photo & account info',
  termsLabel: 'I accept the Terms of Service & Privacy Policy',
  interests: 'Interests',
  gastronomy: 'Gastronomy',
  personal: 'Personal',
  account: 'Account',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'E-mail',
  emailRegisteredError: 'Looks like you already have an account. Go back to the Login page.',
  confirmationCodeTitle: 'Confirmation code',
  confirmationCodeText: 'We sent a confirmation code to your e-mail',
  verifyEmailError: 'Is this e-mail correct?',
  phone: 'Phone',
  address: 'Address',
  password: 'Password',
  passwordRecovery: {
    request: {
      title: 'Forgot your password?',
      text: 'No problem! We can send you an access link to your e-mail:',
      invalid: 'This access link is invalid or has expired. Please confirm your e-mail and we will send you a new one.',
      button: 'Recover',
    },
    sent: {
      title: 'Done!',
      text: 'Please click on the link we have sent to your e-mail.',
    }
  },
  changePassword: 'Change password',
  confirmPassword: 'Confirm password',
  passwordMatchError: 'Password and confirmation doesn`t match',
  requiredField: 'Required field',
  createAccount: 'Create your account',
  myAccount: 'My account',
  deleteAccount: {
    label: 'Delete my account',
    confirmation: {
      title: 'Delete account?',
      text: 'Are you sure you want to delete your Table 4 account? Type *{{key}}* below to confirm your request.',
      warning: '*Warning:* It won`t be possible to recover your account and data after the request is confirmed',
      placeholder: 'Type {{key}} to confirm',
      key: 'DELETE',
      confirm: 'Account deleted!',
    },
  },
  back: 'Back',
  send: 'Send',
  sent: 'Sent',
  resend: 'Resend',
  save: 'Save',
  saved: 'Saved',
  confirm: 'Confirm',
  cancel: 'Cancel',
  okThanks: 'Ok, thanks!',
  learnMore: 'Learn more',
  needHelp: 'Need help?',
  or: 'or',
  you: 'you',
  yes: 'Yes',
  no: 'No',
  others: 'others',
  seats: 'Seat ### Seats',
  copy: 'Copy',
  copied: 'Copied',
  manage: 'Manage',
  pause: 'Pause',
  bonus: 'Bonus',
  free: 'Free',
  change: 'Change',
  guest: 'Guest',
  manageAccount: 'Manage account',
  briefing: [
    {
      key: 'themes',
      text: 'You`re up for *{{themes}}*', 
      generic: 'a table',
    },
    {
      key: 'availability',
      text: '*{{availability}}*', 
    },
    {
      key: 'location',
      text: 'in *{{location}}*',
      base: true,
    }
  ],
  themes: {
    header: 'Explore our themes',
    title: 'Select as many themes as you like ### Liked themes',
  },
  events: {
    header: 'Tables for you',
  },
  tables: {
    confirmedTable: 'Confirmed Table ### Confirmed Tables',
    theme: {
      title: 'The Theme',
    },
    venue: {
      title: 'The Venue',
      tolerance: 'Delay tolerance',
      freeDrink: 'Offers free drink',
      menuLink: 'Digital menu',
    },
    people: {
      title: 'Party of {{count}}',
      status: ['Not confirmed', 'I`ll be there!', 'I`ll be late', 'I can`t make it'],
      signs: {
        Aries: 'Aries',
        Taurus: 'Taurus',
        Gemini: 'Gemini',
        Cancer: 'Cancer',
        Leo: 'Leo',
        Virgo: 'Virgo',
        Libra: 'Libra',
        Scorpio: 'Scorpio',
        Sagittarius: 'Sagittarius',
        Capricorn: 'Capricorn',
        Aquarius: 'Aquarius',
        Pisces: 'Pisces',
      }
    },
    tips: {
      title: 'Tips & Guidelines',
      items: [
        {
          text: '4 tips for dining with new people',
          url: 'https://www.table4.club/blog/posts/cinco-dicas-de-ouro-para-jantares-com-desconhecidos'
        }
      ]
    },
    feedback: {
      intro: {
        header: 'We`d like to hear from you!',
        text: 'Please answer the following questions so we can improve your experience next time. \n\n *We won`t share it with anyone!*',
      },
      table: {
        header: 'How was your table?',
        choices: [
          'Not good',
          'Perfect'
        ],
      },
      people: {
        header: 'How was your fit with {{name}}?',
        choices: [
          'Nothing in common',
          'Great!'
        ],
        noshow: 'Wasn`t there'
      },
      food: {
        header: 'How was the food?',
        choices: [
          'Not good',
          'Amazing!'
        ],
      },
      service: {
        header: 'How was the service?',
        choices: [
          'Not good',
          'Great!'
        ],
      },
      ambient: {
        header: 'How was the ambient?',
        choices: [
          'Not good',
          'Great!'
        ],
      },
      testimonial: {
        header: 'Wanna share any thoughts?',
        placeholder: 'Tell us how it was...'
      },
      end: {
        header: 'Thank you!',
        text: 'We appreciate your feedback! We`ll use it in the future to create a better experience. ; )'
      },
    },
    like: 'Like',
    generic: 'a table',
    cancellation: {
      title: 'Cancel participation',
      text: [
        'You are canceling your participation at {{time}} of {{day}}. **Since there are less than 48h left to the event, your ticket will be processed** and will not be available for future reservations. You will not be able to see this table anymore. \n\n *Are you sure you want to cancel your participation on this table?*',
        'You are canceling your participation at {{time}} of {{day}}. Your ticket will be available for future reservations and you will not be able to see this table anymore. \n\n *Are you sure you want to cancel your participation on this table?*',
      ]
    }
  },
  products: {
    header: 'Reservation',
    title: 'Choose your plan',
    text: 'Would you like to save by getting one of our seat packages?',
    recommended: 'Recommended',
  },
  payment: {
    header: 'Reservation',
    title: 'Payment',
    text: 'Fill in your billing info, please.',
    guests: {
      title: 'Invite your +1',
      text: 'Your guest ticket is guaranteed! *Invite your guest to Table 4* to be able to join you at your next table.',
      button: {
        default: 'Invite your +1',
        change: 'Invite other +1',
        copied: 'Link copied!',
      },
      share: 'Join me at Table 4, my treat!',
      label: 'Guest e-mail',
      placeholder: 'guest@email.com',
      hint: 'By providing your guest`s e-mail address, you confirm you have the proper authorization for sharing that information.',
    },
    pending: 'Your payment is being processed...'
  },
  reservation: {
    header: 'Reservations',
    reserveMySeat: 'Reserve my seat',
    seatReserved: 'Seat reserved!',
    cancelReservation: 'Cancel reservation',
    keepReservation: 'Keep reservation',
    paymentPending: 'Payment pending',
    help: {
      pending: {
        title: 'You`re out of seat credits.',
        text:'*Get credits now to reserve your seat* at the next Table.',
      },
      reserved: {
        title: 'Your seat is reserved for the next Table!',
        text: '*Tables are confirmed (or not) on Mondays – before the event date.* \n We`ll notify you through WhatsApp and our app!'
      }
    },
    cancellation: {
      title: 'Cancel reservation? ### Cancel reservations?',
      text: 'Would you like to cancel your seat reservation for next week?',
      warning: '**If you cancel you`ll keep your seat credits to use them in the future.**',
    },
  },
  tickets: {
    header: {
      default: 'Tickets',
      subscription: 'Subscription',
    },
    title: 'You have {{count}} seat available ### You have {{count}} seats available',
    balance: '{{count}} seat left ### {{count}} seats left',
    expiration: 'expires {{date}}',
    bonus: '{{count}} seat ### {{count}} seats',
    subscription: {
      title: 'We’re sad to see you go!',
      benefits: {
        title: 'Before you {{action}}, here`s what you`ll lose:',
        items: [
          '*Exciting new tables* with themes based on your interests;',
          '*Delicious cuisines* of upcoming top curated restaurants;',
          '*Amazing new friends*;',
          '*Major discount* on Table4 seats;',
          'And, you know, *free drinks*!',
        ],
      },
      info: 'If you`re still sure, **your remaining seat will be available until {{date}}**. And, of course, you can reactivate your subscription and get more tickets anytime.',
      state: {
        renew: 'Renews automatically {{date}}',
        cancelled: 'Subscription cancelled',
        paused: 'Subscription paused',
      },
      manage: 'Manage subscription',
      keep: 'Keep subscription',
      cancel: 'Cancel subscription',
      pause: 'Pause subscription',
    }
  },
  location: {
    header: 'Location',
    title: 'Select the location of your preference',
    prelaunch: {
      survey: {
        title: 'We`re not in your city yet',
        text: 'We`ll notify you as soon as we get there!',
      },
      'Rio de Janeiro': {
        title: 'Reserve your seat now!',
        text: 'We`ll notify you when dates are confirmed ; ) \n *Keep an eye on your e-mail*',
      },
      'São Paulo': {
        title: 'Reserve your seat now!',
        text: 'We`ll notify you in advance! \n *Keep an eye on your e-mail*',
      },
      'Belo Horizonte': {
        title: 'Tables expected to start in August 2024!',
        text: 'We`ll notify you in advance! \n *Keep an eye on your e-mail*',
      },
      'Buenos Aires': {
        title: 'Tables expected to start in September 2024!',
        text: 'We`ll notify you in advance! \n *Keep an eye on your e-mail*',
      },
    },
    survey: {
      header: 'Somewhere \nelse?',
      text: 'Where should we go next?',
      placeholder: 'Type in your City',
    },
  },
  availability: {
    header: 'Availability',
    title: 'Which days are best for you?', 
    meals: {
      label: 'Meals',
      '1': 'Lunch',
      '2': 'Dinner',
      generic: 'Lunch or Dinner',
    },
    days: {
      label: 'Days',
      '1': 'Monday ### Mondays',
      '2': 'Tuesday ### Tuesdays',
      '3': 'Wednesday ### Wednesdays',
      '4': 'Thursday ### Thursdays',
      '5': 'Friday ### Fridays',
      '6': 'Saturday ### Saturdays',
      '7': 'Sunday ### Sundays',
      generic: 'Anyday',
      dividers: [
        'This week',
        'Next week',
        'Later',
      ]
    },
  },
  invite: {
    title: 'Invitations',
    text: 'We`re giving away invites for our first tablers. *Invite your friends and get special gifts!*',
    callToAction: 'Invite friends',
    linkCopied: 'Link copied!',
    shareText: 'Say hi to your new friends from the future!',
  },
  install: {
    text: {
      web: 'For a better experience, install our {{os}} app!',
      outdated: 'New version available, update the app!',
      blocked: 'New version available! Please update the app.',
      updated: 'The Table 4 app is up-to-date.',
    },
    platform: {
      android: 'Get it on Google Play',
      ios: 'Get it on App Store',
    },
    dismiss: 'Not now'
  },
  ghost: {
    title: 'Ghost Access',
    text: 'Type in the id or username to access the app as another tabler.',
    placeholder: 'Id or username',
    logged: 'Logged as *{{name}}*.'
  },
  errors: {
    generic: 'Something went wrong. Try again later, please.',
    payment: 'There was a problem with the payment system. Please wait a few minutes and try again.',
    duplicate_phone: 'The *phone number is already registered* on another account: {{email}}'
  },
  datetime: {
    calendar: {
      sameDay: "[Today] LT",
      nextDay: '[Tomorrow] LT',
      nextWeek: '[Next] dddd LT',
      lastDay: '[Yesterday] LT',
      lastWeek: '[last] dddd LT',
      sameElse: 'l'
    },
    longDateFormat: {
      'l': 'MMM/DD'
    }
  }
}
export const pt = {
  meta: {
    base: 'Table 4',
    title: '%b - Connecting people, one table at a time',
    titleTemplate: '%s | %b',
    hello: {
      login: {
        title: 'Acessar'
      },
      terms: {
        title: 'Termos de serviço & Política de privacidade'
      },
    },
    signup: {
      account: {
        title: 'Criar conta',
      },
      interests: {
        title: 'Interesses',
      },
      gastronomy: {
        title: 'Gastronomia',
      },
      personal: {
        title: 'Pessoal',
      },
    },
    profile: {
      account: {
        title: 'Minha conta',
      },
      interests: {
        title: 'Interesses',
      },
      gastronomy: {
        title: 'Gastronomia',
      },
      personal: {
        title: 'Pessoal',
      },
    },
    settings: {
      location: {
        title: 'Localização',
      },
      tables: {
        title: 'Mesas',
      },
      availability: {
        title: 'Disponibilidade',
      },
      reservation: {
        title: 'Reserva',
        payment: {
          title: 'Pagamento'
        },
        confirmation: {
          title: 'Confirmação'
        },
      }
    },
  },
  hello: {
    logIn: 'Já tem conta?',
    signUp: 'Começar',
    onboarding: [
      {
        title: 'Connecting people, one table at a time.',
        text: ' '
      },
      {
        title: 'Crie seu Perfil',
        text: 'Conte-nos um pouco sobre você, suas comidas favoritas, lifestyle e personalidade.'
      },
      {
        title: 'Encontre sua mesa',
        text: 'Escolha quais mesas gostaria de participar por tema, dia e horário dentro da sua disponibilidade.'
      },
      {
        title: 'Conheça amigos do futuro',
        text: 'Participe das experiências gastronômicas com pessoas selecionadas para você pelo nosso algoritmo.'
      },
    ],
  },
  login: {
    emailButton: 'Continue com E-mail',
    googleButton: 'Continue com Google',
    facebookButton: 'Continue com Facebook',
    appleButton: 'Continue com Apple',
    invalidEmail: 'Informe um e-mail válido',
    logIn: 'Entrar',
    logOut: 'Sair da conta',
    invalidCredentials: 'Usuário e/ou senha incorretos',
    socialUserNotFoundError: 'Usuário não encontrado',
  },
  themes: {
    header: 'Temas sugeridos',
    title: 'Selecione os temas que te interessam ### Mesas selecionadas',
  },
  events: {
    header: 'Mesas para você',
  },
  tables: {
    confirmedTable: 'Mesa confirmada! ### Mesas confirmadas!',
    theme: {
      title: 'Tema',
    },
    venue: {
      title: 'O Restaurante',
      tolerance: 'Tolerância de atraso',
      freeDrink: 'Oferece drink grátis',
      menuLink: 'Menu digital',
    },
    people: {
      title: 'Mesa para {{count}}',
      status: ['Não confirmado', 'Estarei lá!', 'Vou atrasar', 'Não vou poder'],
      signs: {
        Aries: 'Áries',
        Taurus: 'Touro',
        Gemini: 'Gêmeos',
        Cancer: 'Câncer',
        Leo: 'Leão',
        Virgo: 'Virgem',
        Libra: 'Libra',
        Scorpio: 'Escorpião',
        Sagittarius: 'Sagitário',
        Capricorn: 'Capricórnio',
        Aquarius: 'Aquário',
        Pisces: 'Peixes',
      }
    },
    tips: {
      title: 'Dicas & Regras',
      items: [
        {
          text: '5 dicas de ouro para jantares com desconhecidos',
          url: 'https://www.table4.club/blog/posts/cinco-dicas-de-ouro-para-jantares-com-desconhecidos'
        }
      ]
    },
    feedback: {
      intro: {
        header: 'Queremos ouvir você!',
        text: 'Por favor, responda as seguintes perguntinhas para que possamos melhorar a sua experiência. \n\n *Nós não compartilharemos com ninguém!*',
      },
      table: {
        header: 'Como foi a sua mesa?',
        choices: [
          'Não foi legal',
          'Perfeita!'
        ],
      },
      people: {
        header: 'Como foi o seu fit com {{name}}?',
        choices: [
          'Nada a ver',
          'Ótimo!'
        ],
        noshow: 'Não estava lá'
      },
      food: {
        header: 'O que achou da comida?',
        choices: [
          'Não gostei',
          'Deliciosa!'
        ],
      },
      service: {
        header: 'O que achou do serviço?',
        choices: [
          'Ruim',
          'Muito bom!'
        ],
      },
      ambient: {
        header: 'O que achou do ambiente?',
        choices: [
          'Não curti',
          'Ótimo!'
        ],
      },
      testimonial: {
        header: 'Quer compartilhar algum pensamento?',
        placeholder: 'Conte como foi...'
      },
      end: {
        header: 'Obrigado!',
        text: 'Levaremos seu feedback em consideração para suas futuras mesas. ; )'
      },
    },
    like: 'Curti',
    generic: 'uma mesa',
    cancellation: {
      title: 'Cancelar participação',
      text: [
        'Você está cancelando sua participação na mesa às {{time}} do dia {{day}}. **Por faltar menos de 48h para o evento, seu ticket será processado** e não poderá mais ser utilizado em próximas reservas. A mesa deixará de aparecer para você no aplicativo. \n\n *Você realmente deseja cancelar sua participação nessa mesa?*',
        'Você está cancelando sua participação na mesa às {{time}} do dia {{day}}. Seu ticket será mantido para as próximas reservas e a mesa deixará de aparecer para você no aplicativo. \n\n *Você realmente deseja cancelar sua participação nessa mesa?*',
      ]
    }
  },
  products: {
    header: 'Reserva',
    title: 'Selecione seu plano',
    text: 'Você gostaria de economizar com nossa assinatura mensal?',
    recommended: 'Recomendado',
  },
  payment: {
    header: 'Reserva',
    title: 'Pagamento',
    text: 'Informe abaixo os dados de pagamento, por favor.',
    guests: {
      title: 'Convide seu +1',
      text: 'O ticket do seu convidado está garantido! *Convide seu +1 para o Table 4* para poder se juntar a você na sua próxima mesa.',
      button: {
        default: 'Convidar +1',
        change: 'Convidar outro +1',
        copied: 'Link copiado!',
      },
      share: 'Vamos na próxima mesa do Table 4?!',
      label: 'E-mail convidado',
      placeholder: 'convidado@email.com',
      hint: 'Ao informar o e-mail do convidado, você confirma que tem a devida autorização para compartilhar essa informação.',
    },
    pending: 'Pagamento sendo processado...'
  },
  tickets: {
    header: {
      default: 'Tickets',
      subscription: 'Assinatura',
    },
    title: 'Você tem {{count}} assento disponível ### Você tem {{count}} assentos disponíveis',
    balance: '{{count}} assento restante ### {{count}} assentos restantes',
    expiration: 'expira {{date}}',
    bonus: '{{count}} assento ### {{count}} assentos',
    subscription: {
      title: 'Ficamos tristes de você ir embora!',
      benefits: {
        title: 'Antes de {{action}}, confira o que você está perdendo:',
        items: [
          '*Novas mesas bacanas* com temas baseados no seu perfil;',
          '*Gastronomia top* de uma curadoria de restaurantes novos;',
          '*Novos amigos maravilhosos*;',
          '*Super desconto* em assentos no Table4;',
          'E, você sabe, *drinks grátis*!',
        ],
      },
      info: 'Caso ainda queira {{action}}, **seus assentos restantes ficarão disponíveis até {{date}}**. E, claro, você pode reativar sua assinatura e adquirir mais assentos quando quiser.',
      state: {
        renew: 'Renova automaticamente {{date}}',
        cancelled: 'Assinatura cancelada',
        paused: 'Assinatura pausada',
      },
      manage: 'Alterar assinatura',
      keep: 'Manter assinatura',
      cancel: 'Cancelar assinatura',
      pause: 'Pausar assinatura',
    }
  },
  reservation: {
    header: 'Reserva',
    reserveMySeat: 'Reservar meu lugar',
    seatReserved: 'Lugar reservado!',
    cancelReservation: 'Cancelar reserva',
    keepReservation: 'Manter reserva',
    paymentPending: 'Pagamento pendente',
    help: {
      pending: {
        title: 'Você está sem créditos de assento',
        text:'*Compre créditos agora mesmo para reservar seu lugar* na próxima Mesa.',
      },
      reserved: {
        title: 'Seu lugar está reservado na próxima mesa!',
        text: '*As mesas são confirmadas (ou não) aos domingos – anterior à data do evento. * \n Te enviaremos mensagem por WhatsApp e aqui no nosso app!'
      }
    },
    cancellation: {
      title: 'Cancelar reserva? ### Cancelar reservas?',
      text: 'Você gostaria de cancelar sua reserva para a semana que vem?',
      warning: '**Ao cancelar você fica com o crédito de um assento para usar no futuro.**',
    },
  },
  fillInfo: 'Adicione sua foto & informações',
  termsLabel: 'Aceito os Termos de Serviço & Política de Privacidade',
  interests: 'Interesses',
  gastronomy: 'Gastronomia',
  personal: 'Pessoal',
  account: 'Conta',
  firstName: 'Nome',
  lastName: 'Sobrenome',
  email: 'E-mail',
  emailRegisteredError: 'Parece que já existe uma conta com este e-mail.',
  confirmationCodeTitle: 'Código de confirmação',
  confirmationCodeText: 'Enviamos um código de confirmação para seu e-mail',
  verifyEmailError: 'Este e-mail está correto?',
  phone: 'Celular',
  address: 'Endereço',
  password: 'Senha',
  passwordRecovery: {
    request: {
      title: 'Esqueceu sua senha?',
      text: 'Sem problemas! Podemos enviar um link de acesso para o seu e-mail:',
      invalid: 'Este link de acesso é inválido ou expirou. Confirme seu e-mail e enviaremos um link novo.',
      button: 'Esqueci',
    },
    sent: {
      title: 'Prontinho!',
      text: 'Por favor clique no link de acesso que enviamos para o seu e-mail.',
    }
  },
  changePassword: 'Alterar senha',
  confirmPassword: 'Confirme sua senha',
  passwordMatchError: 'Confirmação não bate com a senha',
  requiredField: 'Campo obrigatório',
  createAccount: 'Crie sua conta',
  deleteAccount: {
    label: 'Apagar minha conta',
    confirmation: {
      title: 'Apagar conta?',
      text: 'Tem certeza que deseja apagar sua conta no Table 4? Digite *APAGAR* abaixo para confirmar seu pedido.',
      warning: '*Atenção:* Não será possível recuperar sua conta e dados depois que o pedido for realizado.',
      placeholder: 'Digite {{key}} para confirmar',
      key: 'APAGAR',
      confirm: 'Conta apagada!',
    },
  },
  myAccount: 'Minha conta',
  back: 'Voltar',
  send: 'Enviar',
  resend: 'Reenviar',
  sent: 'Enviado',
  save: 'Salvar',
  saved: 'Salvo',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  okThanks: 'Ok, obrigado!',
  learnMore: 'Saiba mais',
  needHelp: 'Preciso de ajuda',
  or: 'ou',
  you: 'você',
  yes: 'Sim',
  no: 'Não',
  others: 'outras',
  seats: 'Assento ### Assentos',
  copy: 'Copiar',
  copied: 'Copiado',
  manage: 'Alterar',
  pause: 'Pausar',
  bonus: 'Bônus',
  free: 'Grátis',
  change: 'Alterar',
  guest: 'Convidado',
  manageAccount: 'Alterar conta',
  briefing: [
    {
      key: 'themes',
      text: 'Você topa *{{themes}}*', 
      generic: 'uma mesa',
    },
    {
      key: 'availability',
      text: '*{{availability}}*', 
    },
    {
      key: 'location',
      text: 'em *{{location}}*',
      base: true,
    }
  ],
  location: {
    header: 'Localização',
    title: 'Selecione seu lugar de preferência',
    prelaunch: {
      survey: {
        title: 'Ainda não estamos na sua cidade',
        text: 'Avisaremos você assim que chegarmos aí!',
      },
      'Rio de Janeiro': {
        title: 'Reserve sua mesa agora mesmo!',
        text: 'Avisaremos você assim que as datas estiverem confirmadas! \n Fique atento ao seu e-mail! ;)',
      },
      'São Paulo': {
        title: 'Reserve sua mesa agora mesmo!',
        text: 'Avisaremos você assim que as datas estiverem confirmadas! \n Fique atento ao seu e-mail! ;)',
      },
      'Belo Horizonte': {
        title: 'Mesas previstas para Agosto/2024!',
        text: 'Avisaremos você assim que as datas estiverem confirmadas! \n Fique atento ao seu e-mail! ;)',
      },
      'Buenos Aires': {
        title: 'Mesas previstas para Setembro/2024!',
        text: 'Avisaremos você assim que as datas estiverem confirmadas! \n Fique atento ao seu e-mail! ;)',
      },
    },
    survey: {
      header: 'Outro lugar?',
      text: 'Partiu para onde?',
      placeholder: 'Digite sua cidade',
    },
  },
  availability: {
    header: 'Disponibilidade',
    title: 'Quais dias são melhores para você?',
    meals: {
      label: 'Refeições',
      '1': 'Almoço',
      '2': 'Jantar',
      generic: 'Almoço ou Jantar'
    },
    days: {
      label: 'Dias',
      '1': 'Segunda ### Segundas',
      '2': 'Terça ### Terças',
      '3': 'Quarta ### Quartas',
      '4': 'Quinta ### Quintas',
      '5': 'Sexta ### Sextas',
      '6': 'Sábado ### Sábados',
      '7': 'Domingo ### Domingos',
      generic: 'Qualquer dia',
      dividers: [
        'Esta semana',
        'Próxima semana',
        'Mais adiante',
      ]
    },
  },
  invite: {
    title: 'Convites',
    text: 'Estamos distribuindo convites para os primeiros tablers. *Convide seus amigos e receba presentes especiais!*',
    callToAction: 'Convidar amigos',
    linkCopied: 'Link copiado!',
    shareText: 'Diga "oi" para seus novos amigos do futuro!',
  },
  install: {
    text: {
      web: 'Para uma melhor experiência, instale nosso app {{os}}!',
      outdated: 'Nova versão disponível, atualize o app!',
      blocked: 'Nova versão disponível! Atualize o app, por favor.',
      updated: 'O app Table 4 está atualizado!',
    },
    platform: {
      android: 'Baixe no Google Play',
      ios: 'Baixe na App Store',
    },
    dismiss: 'Agora não'
  },
  ghost: {
    title: 'Acesso fantasma',
    text: 'Digite o id ou username para acessar o app como outro usuário.',
    placeholder: 'Id ou username',
    logged: 'Logado como *{{name}}*.'
  },
  errors: {
    generic: 'Algo deu errado. Tente novamente, por favor.',
    payment: 'Houve um problema no sistema de pagamento. Aguarde alguns minutos e tente novamente, por favor.',
    duplicate_phone: 'O *telefone já está registrado* em outra conta: {{email}}'
  },
  datetime: {
    longDateFormat : {
      LT: "HH[h]mm",
      l: 'DD/MMM'
    },
  }
}
export const es = {
  hello: {
    logIn: 'Have an account? Log in',
    signUp: 'Get started',
    onboarding: [
      {
        title: 'Connecting people, one table at a time.',
        text: ' '
      },
      {
        title: 'Crea tu perfil',
        text: 'Tell us a bit about yourself: favorite cuisines, interests in life, and a few mildly personal stuff.'
      },
      {
        title: 'Encuentra tu mesa',
        text: 'Elige las mesas en las que te gustaría participar por tema, día y hora dentro de tu disponibilidad.'
      },
      {
        title: 'Conoce amigos del futuro',
        text: 'Participa en las experiencias gastronómicas con personas seleccionadas para ti por nuestro algoritmo.'
      },
    ],
  },
  login: {
    emailButton: 'Continue con E-mail',
    googleButton: 'Continue con Google',
    facebookButton: 'Continue con Facebook',
    appleButton: 'Continue con Apple',
    invalidEmail: 'Ingrese un correo electrónico válido',
    logIn: 'Accesar',
    logOut: 'Salir',
    invalidCredentials: 'Incorrect username and/or password',
    socialUserNotFoundError: 'Usuário não encontrado',
  },
  tables: {
    header: 'Tables',
    title: 'Selected tables',
    tablesForMe: 'Next week`s tables',
    like: 'like',
    generic: 'a table',
  },
  fillInfo: 'Agrega foto & info de tu cuenta',
  termsLabel: 'Acepto los Términos de Servicio y Política de Privacidad',
  interests: 'Intereses',
  gastronomy: 'Gastronomía',
  personal: 'Personal',
  account: 'Conta',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo electrónico',
  emailRegisteredError: 'Correo electrónico ya registrado',
  confirmationCodeTitle: 'Código de confirmação',
  confirmationCodeText: 'Enviamos um código de confirmação para seu e-mail',
  verifyEmailError: 'Este e-mail está correto?',
  phone: 'Celular',
  password: 'Contraseña',
  changePassword: 'Cambiar la contraseña',
  confirmPassword: 'Confirmar Contraseña',
  passwordMatchError: 'La confirmación no coincide con la contraseña',
  requiredField: 'Campo requerido',
  createAccount: 'Crea su cuenta',
  myAccount: 'Mi cuenta',
  back: 'Volver',
  send: 'Enviar',
  resend: 'Reenviar',
  sent: 'Enviado',
  save: 'Ahorrar',
  saved: 'Ahorrado',
  confirm: 'Confirmar',
  learnMore: 'Aprende más',
  or: 'o',
  others: 'others',
  briefing: [
    {
      key: 'tables',
      text: 'You`re up for *{{tables}}*', 
      generic: 'a table',
    },
    {
      key: 'availability',
      text: '*{{availability}}*', 
    },
    {
      key: 'location',
      text: 'in *{{location}}*',
      base: true,
    }
  ],
  location: {
    header: 'Local',
    title: 'Selecciona tu local preferido',
    prelaunch: {
      survey: {
        title: 'We`re not in your city yet',
        text: 'We`ll notify you as soon as we get there!',
      },
      'Rio de Janeiro': {
        title: 'Próximas mesas abrirão em Marzo/2024!',
        text: 'Avisaremos você assim que as datas estiverem confirmadas! \n Fique atento ao seu e-mail! ;)',
      },
      'São Paulo': {
        title: 'Mesas previstas para Abril/2024!',
        text: 'Avisaremos você assim que as datas estiverem confirmadas! \n Fique atento ao seu e-mail! ;)',
      },
      'Buenos Aires': {
        title: 'Mesas previstas para Mayo/2024!',
        text: 'Avisaremos você assim que as datas estiverem confirmadas! \n Fique atento ao seu e-mail! ;)',
      },
    },
    survey: {
      header: 'Somewhere \nelse?',
      text: 'Let us know where you at',
      placeholder: 'Escribe tu ciudad',
    },
  },
  availability: {
    header: 'Availability',
    title: 'Select your schedule of preference',
    meals: {
      generic: 'lunch or dinner',
    },
    days: {
      generic: 'anyday',
    },
  },
  invite: {
    title: 'Invitations',
    text: 'We`re giving away invites for our first tablers. *Invite your friends and get special gifts!*',
    callToAction: 'Copiar link',
    linkCopied: 'Link copiado!',
    shareText: 'Say hi to your new your new friends from the future!',
  },
  errors: {
    generic: 'Something went wrong. Try again later.',
  }
}

export function test (supported, languages) {
  
  const compareLanguages = (ref, obj, path='') => {
    for (const key in ref) {
      if (ref.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key;

        if (!obj.hasOwnProperty(key)) {
          console.warn(`${newPath} is missing the translation`);
        } else if (typeof ref[key] !== typeof obj[key]) {
          console.warn(`${newPath} has different types`);
        } else if (typeof ref[key] === 'object' && typeof obj[key] === 'object') {
          compareLanguages(ref[key], obj[key], newPath);
        }
      }
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && !ref.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key;
        console.warn(`${newPath} is missing in ref`);
      }
    }
  }

  const ref = supported[0];
  let translations = JSON.parse(JSON.stringify(languages));
  delete translations[ref];
  for (const k in translations) {
    compareLanguages(languages[ref], translations[k]);
  };
}

export function translate (keys, data, fallback=null) {
  const sentence = Array.isArray(keys);
  if (!Array.isArray(keys)) keys = [keys];
  for (const k in keys) {
    let key = keys[k];
    const path = `$vuetify.${key}`;
    let text = !!key ? this.$vuetify.locale.t(path) : null;
    if (!!text&&text.includes('###')) {
      const options = piper(text, '###');
      text = _.has(data, 'count') && data.count==1 ? options[0] : options[1];
    }
    // console.log('$translate', key, text);
    keys[k] = text==path ? fallback : formatText(text, data);
  }
  keys = keys.filter(word => word !== null && word !== undefined);
  let result = keys.join(' ');
  return sentence ? result.charAt(0).toUpperCase() + result.slice(1).toLowerCase() : result;
}

export function sentence (words) {
  if (!Array.isArray(words) || words.length === 0) return '';

  // Join the words into a single string
  const sentence = words.join(' ');

  // Capitalize the first letter and ensure the rest is lowercase
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
}

export const supported = ['en', 'pt'];

export default { 
  en, 
  pt, 
  es, 
  translate, 
  supported,
  test
}