import { logout } from '@/services/global'

export function get ($, token, resolve, reject, complete) {
  console.log('user.get... ');
  
  const name = 'allMyinfo';
  const headers = {
    'Authorization': `Bearer ${token}`,
  }

  $.$api({
    url: '/graphql/',
    method: 'post',
    headers,
    data: {
      query: `query ${name} {
        ${name} {
          id
          username
          typeAuth
          firstName
          lastName
          email
          phone
          image
          dateTerms
          emailValidation
          inviteToken
          isActive
          isSuperuser
          isTemporary
          prefZones
          responseSet {
            id
            interviewUuid
            survey {
              id
              name
              publishDate
              expireDate
              questions {
                id
                text
                textPt
                textEs
                type
                choices
                choicesPt
                choicesEs
                order
                category {
                  name
                }
              }
            }
            answers {
              body
              question {
                id
                type
              }
              created
            }
          }
        }
      }`,
      variables: {}
    }
  })
  .then(response => {
    console.log('user.get => ', response.data[name]);

    let user = null;
    let surveys = null;
    if (!!response.data[name]) {
      let responseSet = null;
      ({ responseSet, ...user } = response.data[name][0]);
      surveys = _.map(responseSet, item => {
        const answers = _.mapValues(_.groupBy(item.answers, 'question.id'), answers => {
          return _.reduce(answers, (last, answer) => {
            return last===null || $.$moment(last.created).isBefore(answer.created) ? answer : last;
          }, null);
        });
        return {
          ...item,
          answers
        }
      });;
    }else{
      throw new Error(401);
    }
    resolve(user, surveys);
  })
  .catch(error => {
    if(error.message==401||(_.has(error.response, 'status') && error.response.status==401)){
      logout('user.get');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }else{
      // $.handleError(error);
      if (!!reject) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (!!complete) complete();
  });
};

export function auth ($, username, password, resolve, reject, complete) {
  console.log('user.auth... ');
  
  const name = 'tokenAuth';

  $.$api({
    url: '/graphql/',
    method: 'post',
    data: {
      query: `mutation ${name} {
        ${name} (username: "${username}", password: "${password}") {
          token
          user {
            id
            username
            typeAuth
            firstName
            lastName
            email
            phone
            image
            dateTerms
            emailValidation
            inviteToken
            isActive
            isSuperuser
            isTemporary
            prefZones
            responseSet {
              id
              interviewUuid
              survey {
                id
                name
                publishDate
                expireDate
                questions {
                  id
                  text
                  textPt
                  textEs
                  type
                  choices
                  choicesPt
                  choicesEs
                  order
                  category {
                    name
                  }
                }
              }
              answers {
                body
                question {
                  id
                  type
                }
                created
              }
            }
          }
        }
      }`,
      variables: {}
    }
  })
  .then(response => {
    console.log('user.auth => ', response.data[name]);

    let auth = null;
    let user = null;
    let surveys = null;
    if (!!response.data[name]) {
      auth = {
        username,
        token: response.data[name].token,
        timestamp: Date.now()
      };
      let responseSet = null;
      ({ responseSet, ...user } = response.data[name].user);
      surveys = responseSet;
    }else{
      console.log('failed');
      throw new Error(401);
    }

    resolve(auth, user, surveys);
  })
  .catch(error => {
    console.warn('user.auth !> ', JSON.stringify(error));
    if(error.message==401||(_.has(error.response, 'status') && error.response.status==401)){
      // $.getout('user.auth');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }
    if (!!reject) reject(error);
  })
  .finally(() => {
    // callback function
    if (!!complete) complete();
  });
};

export function update ($, token, data, resolve, reject, complete) {
  console.log('user.update... ');
  
  const name = 'updateUserext';
  const headers = {
    'Authorization': `Bearer ${token}`,
  }
  data = _.join(_.reduce(_.omit(data, ['emailValidation', 'isSuperuser']), (d, value, key) => {
    value = _.isString(value) ? `"${value}"` : value;
    d.push(`${key}: ${value}`);
    return d;
  }, []), ', ');

  $.$api({
    url: '/graphql/',
    method: 'post',
    headers,
    data: {
      query: `mutation ${name} {
        ${name} (${data}) {
          userext {
            id
            username
            typeAuth
            firstName
            lastName
            email
            phone
            image
            dateTerms
            emailValidation
            inviteToken
            isActive
            isSuperuser
            isTemporary
            prefZones
          }
        }
      }`,
    }
  })
  .then(response => {
    console.log('user.update => ', response.data[name]);

    resolve(response.data[name].userext);
  })
  .catch(error => {
    console.warn('user.update => ', error);
    if(_.has(error.response, 'status') && error.response.status==401){
      // $.getout('user.auth');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }else{
      // $.handleError(error);
      if (!!reject) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (!!complete) complete();
  });
};

export function location ($, token, zones=null) {
  console.log('user.location... ');
  
  const name = 'myZones';
  const headers = {
    'Authorization': `Bearer ${token}`,
  }
  const payload = !!zones ? `(prefZones: "${zones}")` : '';

  return $.$api({
    url: '/graphql/',
    method: 'post',
    headers,
    data: {
      query: `mutation ${name} {
        ${name} ${payload} {
          success
          zonesUser
        }
      }`,
    }
  })
  .then(response => {
    console.log('user.location => ', response.data[name]);

    return response.data[name]?.zonesUser;
  })
  .catch(error => {
    console.warn('user.location !> ', error);
    if(_.has(error.response, 'status') && error.response.status==401){
      // $.getout('user.auth');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }
    return error;
  })
};

export function create ($, data, resolve, reject, complete) {
  console.log('user.create... ');
  
  const name = 'createUserext';

  data = _.join(_.reduce(_.omit(data, ['emailValidation', 'id', 'isSuperuser']), (d, value, key) => {
    value = _.isString(value) ? `"${value}"` : value;
    d.push(`${key}: ${value}`);
    return d;
  }, []), ', ');

  $.$api({
    url: '/graphql/',
    method: 'post',
    data: {
      query: `mutation ${name} {
        ${name} (${data}) {
          userext {
            id
            username
            typeAuth
            firstName
            lastName
            email
            phone
            image
            dateTerms
            emailValidation
            inviteToken
            isActive
            isSuperuser
            isTemporary
            prefZones
          }
          error {
            codigo
            description
            message
          }
        }
      }`
    }
  })
  .then(response => {
    console.log('user.create => ', response.data[name]);

    resolve(response.data[name].userext, response.data[name].error);
  })
  .catch(error => {
    console.warn('user.create => ', error);
    if(_.has(error.response, 'status') && error.response.status==401){
      // $.getout('user.auth');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }else{
      // $.handleError(error);
      if (!!reject) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (!!complete) complete();
  });
};

export function remove ($, token, resolve, reject, complete) {
  console.log('user.remove... ');
  
  const name = 'anonymizeUser';
  const headers = {
    'Authorization': `Bearer ${token}`,
  }

  $.$api({
    url: '/graphql/',
    method: 'post',
    headers,
    data: {
      query: `mutation ${name} {
        ${name} {
          success
        }
      }`
    }
  })
  .then(response => {
    console.log('user.remove => ', response.data[name]);

    resolve(response.data[name].success);
  })
  .catch(error => {
    console.warn('user.remove => ', error);
    if(_.has(error.response, 'status') && error.response.status==401){
      // $.getout('user.auth');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }else{
      // $.handleError(error);
      if (!!reject) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (!!complete) complete();
  });
};

export const validation = { 
  send ($, token, username, resolve, reject, complete) {
    console.log('user.validation.send... ');
    
    const name = 'sendEmailConfirmationByUsername';
    const headers = {
      'Authorization': `Bearer ${token}`,
    }

    $.$api({
      url: '/graphql/',
      method: 'post',
      headers,
      data: {
        query: `mutation ${name} {
          ${name} (username: "${username}") {
            success
          }
        }`,
        variables: {}
      }
    })
    .then(response => {
      console.log('user.validation.send => ', response.data[name]);

      resolve(response.data[name].success);
    })
    .catch(error => {
      console.warn('user.validation.send => ', error);
      if(_.has(error.response, 'status') && error.response.status==401){
        // $.getout('user.auth');
        // $.handleError(error, 'Sua sessão expirou...', true);
      }else{
        // $.handleError(error);
        if (!!reject) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (!!complete) complete();
    });
  },

  recover ($, username, resolve, reject, complete) {
    console.log('user.validation.recover... ');
    
    const name = 'sendEmailResetPwdByUsername';

    $.$api({
      url: '/graphql/',
      method: 'post',
      data: {
        query: `mutation ${name} {
          ${name} (username: "${username}") {
            success
          }
        }`,
        variables: {}
      }
    })
    .then(response => {
      console.log('user.validation.recover => ', response.data[name]);
      const success = _.get(response.data[name], 'success', false);
      resolve(success);
    })
    .catch(error => {
      console.warn('user.validation.recover => ', error);
      if(_.has(error.response, 'status') && error.response.status==401){
        // $.getout('user.auth');
        // $.handleError(error, 'Sua sessão expirou...', true);
      }else{
        // $.handleError(error);
        if (!!reject) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (!!complete) complete();
    });
  },

  validate ($, token, email, otp, resolve, reject, complete) {
    console.log('user.validation.validate... ');
    
    const name = 'validateUserOtp';
    const headers = !!token ? {
      'Authorization': `Bearer ${token}`,
    } : null;

    $.$api({
      url: '/graphql/',
      method: 'post',
      data: {
        query: `mutation ${name} {
          ${name} (email: "${email}", otptoken: "${otp}") {
            success
            authToken
          }
        }`
      }
    })
    .then(response => {
      console.log('user.validation.validate => ', response.data[name]);

      const success = _.get(response.data[name], 'success', false);
      let auth = null;
      if (success) {
        auth = {
          username: email,
          token: response.data[name].authToken,
          timestamp: Date.now()
        };
      }

      resolve(success, auth);
    })
    .catch(error => {
      console.warn('user.validation.validate => ', error);
      if(_.has(error.response, 'status') && error.response.status==401){
        // $.getout('user.auth');
        // $.handleError(error, 'Sua sessão expirou...', true);
      }else{
        // $.handleError(error);
        if (!!reject) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (!!complete) complete();
    });
  },
}

export function device ($, token, data=null) {
  console.log('user.device... ');
  
  const name = 'myDeviceInfo';
  const headers = {
    'Authorization': `Bearer ${token}`,
  }
  const payload = !!data ? '(' + _.join(_.reduce(data, (d, value, key) => {
    value = _.isString(value) ? `"${value}"` : value;
    d.push(`${key}: ${value}`);
    return d;
  }, []), ', ') + ')' : '';

  return $.$api({
    url: '/graphql/',
    method: 'post',
    headers,
    data: {
      query: `mutation ${name} {
        ${name} ${payload} {
          deviceInfo {
            appInfo
            deviceInfo
          }
        }
      }`,
    }
  })
  .then(response => {
    console.log('user.device => ', response.data[name]);

    return response.data[name]?.deviceInfo;
  })
  .catch(error => {
    console.warn('user.device !> ', error);
    if(_.has(error.response, 'status') && error.response.status==401){
      // $.getout('user.auth');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }
    return error;
  })
};

export function ghost ($, token, key) {
  console.log('user.ghost... ');

  const isId = !key.includes('@');
  const name = !isId ? 'ghostToken' : 'ghostTokenById';
  const headers = {
    'Authorization': `Bearer ${token}`,
  }
  const payload = `(${isId ? 'id' : 'username'}: ${isId ? key : '"'+key+'"'})`

  return $.$api({
    url: '/graphql/',
    method: 'post',
    headers,
    data: {
      query: `mutation ${name} {
        ${name} ${payload} {
          token
        }
      }`,
    }
  })
  .then(response => {
    console.log('user.ghost => ', response.data[name]);
    if (response.data[name]?.token) {
      return response.data[name]?.token;
    }else{
      throw new Error(401);
    }
  })
  .catch(error => {
    console.warn('user.ghost !> ', error);
    if(_.has(error.response, 'status') && error.response.status==401){
      // $.getout('user.auth');
      // $.handleError(error, 'Sua sessão expirou...', true);
    }
    return error;
  })
};

export default {
  get,
  auth,
  update,
  location,
  create,
  device,
  validation
}